import React, { useState, useEffect, useRef } from "react";
import "./Video.css";
import Video1 from "../Video/home.mp4";
import Video2 from "../Video/home.webm";
import mobile from "./mobilehomepage.mp4";
import { Modal } from "react-bootstrap";
import { animateScroll as scroll } from "react-scroll";
import home from "../../Assetss/home-convert.jpg";
import nurse from "../../Assetss/nurseIcon.png";
import practice from "../../Assetss/practiceIcon.png";
import logo1 from "../../Assetss/apple (1).png";
import logo2 from "../../Assetss/Group.png";

const Video = () => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const [formData, setFormData] = useState({
    name: "",
    role: "",
    email: "",
    consentCheckbox: false,
  });
  const [errors, setErrors] = useState({});
  const [submitMessage, setSubmitMessage] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showNurseModal, setShowNurseModal] = useState(false);

  const videoRef = useRef(null);
  const mobileVideoRef = useRef(null);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (document.fullscreenElement || document.webkitFullscreenElement) {
        document.exitFullscreen();
      }
    };

    const video = videoRef.current;
    const mobileVideo = mobileVideoRef.current;

    if (video) {
      video.addEventListener("webkitbeginfullscreen", handleFullscreenChange);
      video.addEventListener("webkitendfullscreen", handleFullscreenChange);
    }

    if (mobileVideo) {
      mobileVideo.addEventListener(
        "webkitbeginfullscreen",
        handleFullscreenChange
      );
      mobileVideo.addEventListener(
        "webkitendfullscreen",
        handleFullscreenChange
      );
    }

    return () => {
      if (video) {
        video.removeEventListener(
          "webkitbeginfullscreen",
          handleFullscreenChange
        );
        video.removeEventListener(
          "webkitendfullscreen",
          handleFullscreenChange
        );
      }
      if (mobileVideo) {
        mobileVideo.removeEventListener(
          "webkitbeginfullscreen",
          handleFullscreenChange
        );
        mobileVideo.removeEventListener(
          "webkitendfullscreen",
          handleFullscreenChange
        );
      }
    };
  }, []);

  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = () => {
    setShowForm(false);
    setFormData({
      name: "",
      role: "",
      email: "",
      consentCheckbox: false,
    });
    setErrors({});
    setSubmitMessage("");
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await fetch(
          "https:/api.drluisa.co.uk/nomad/send-email",
          {
            method: "POST",
            body: JSON.stringify({
              subject: "New Registration Request",
              text: `Name: ${formData.name}\nRole: ${formData.role}\nUser Email: ${formData.email}.`,
            }),
            headers: { "Content-Type": "application/json" },
          }
        );
        const data = await response.json();
        setSubmitMessage(data.message);
        setShowForm(false);
        setFormData({
          name: "",
          role: "",
          email: "",
          consentCheckbox: false,
        });

        // Show success modal
        setShowSuccessModal(true);
      } catch (error) {
        console.error(error);
        setSubmitMessage("Failed to send email");
      }
    } else {
      setSubmitMessage("Form validation failed");
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    if (!formData.role) {
      errors.role = "Please select a role";
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = "Invalid email address";
    }

    if (!formData.consentCheckbox) {
      errors.consentCheckbox = "You must consent to the privacy policy";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCloseSuccessModal = () => setShowSuccessModal(false);

  const handleNurseClick = () => {
    setShowForm(false);
    setShowNurseModal(true);
  };

  const handleProceed = () => {
    window.location.href =
      "https://web.nomadnurse.co.uk/#nurse-email-verification";
  };

  const text = "Connecting Nurses to Practices";
  const words = text.split(" ");
  const lastWord = words.pop();
  const text1 = "Bridging Excellence Together";
  const words1 = text1.split(" ");
  const lastWord1 = words1.pop();

  return (
    <div className="video-container2">
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        preload="auto"
        className="video"
        poster={home}
        playsInline
      >
        <source src={Video1} type="video/mp4" poster={home} />
        <source src={Video2} type="video/webm" poster={home} />
      </video>
      <div className="video-gap-container">
        <video
          ref={mobileVideoRef}
          autoPlay
          loop
          muted
          preload="auto"
          playsInline
        >
          <source src={mobile} type="video/mp4" poster={home} />
        </video>
      </div>
      <div className="video-heading-container">
        <h2>
          <span className="sentence">
            {words.join(" ")} <span className="last-word">{lastWord}</span>
          </span>
        </h2>{" "}
        <h2>
          <span className="sentence">
            {words1.join(" ")} <span className="last-word">{lastWord1}</span>
          </span>
        </h2>
        <button onClick={handleOpenForm}>Join us</button>
      </div>

      <Modal show={showForm} onHide={handleCloseForm} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="please-selcettt">Please select your role</div>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="nurse-practice-containerrrr">
            <div className="nurse-image-flexxxxxx">
              <div
                onClick={handleNurseClick}
                className="image-text-open"
                style={{ cursor: "pointer" }}
              >
                <img src={nurse} alt="Nurse" />
                <div className="nurse-container-opennnn">
                  <p>Nurse</p>
                </div>
              </div>
              <a
                href="https://web.nomadnurse.co.uk/#practice-email-verification"
                style={{ textDecoration: "none", color: "white" }}
              >
                <div className="image-text-open">
                  <img src={practice} alt="Practice" />
                  <div className="nurse-container-opennnn">
                    <p>Practice</p>
                  </div>
                </div>
              </a>
            </div>
            <div className="button-container-fluxxxxxxxxxxxxxxx">
              <a href="https://apps.apple.com/app/nomad-nurse-app/id6503719145">
                <button>
                  <div className="img-hhhhel">
                    <div className="img-hhhhel-ggg">
                      <img src={logo1} alt="Apple Store" />
                    </div>
                    Download App
                  </div>
                </button>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.nomad_nurse.app&pli=1">
                <button>
                  <div className="img-hhhhel">
                    <div className="img-hhhhel-ggg">
                      <img src={logo2} alt="Google Play Store" />
                    </div>
                    Download App
                  </div>
                </button>
              </a>
            </div>
            <div className="forget-para-tell">
              <p>Don’t forget to download the app on your mobile phone too</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showNurseModal}
        onHide={() => setShowNurseModal(false)}
        centered
      >
        <Modal.Body>
          <div className="nurse-proceed-container-shop">
            <div className="before-you-proceed">Before you start:</div>
            <div className="you-before-proceed">
              You will need to upload the following items as part of your{" "}
              application :
            </div>
            <ul id="testul">
              <li>GDC Certificate</li>
              <li>Indemnity Certificate</li>
              <li>Enhanced DBS</li>
              <li>Hepatitis B</li>
            </ul>
            <div className="you-before-proceed-deep">
              You will also need to enter your bank details for payments
            </div>
            <div className="proceed-cancel-buttons-orifin">
              <div className="proceed-cancel-buttons">
                <div onClick={handleProceed} className="proceed-button-orifin">
                  Proceed
                </div>
                <div
                  className="proceed-button-orifin"
                  onClick={() => setShowNurseModal(false)}
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Thank You!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your email has been successfully sent.</p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Video;

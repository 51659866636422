import React from "react";
import logo from "../../Assetss/logo.png";
// import logo1 from "../../Assests/Monochrome on Transparent 4.png";
import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const navbarStyle = {
    fontFamily: "'Poppins', sans-serif",
    width: "100%",
    backgroundColor: "white",
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-white" style={navbarStyle}>
        <div
          className="container-fluid"
          style={{
            position: "fixed",
            top: "0",
            backgroundColor: "white",
            zIndex: "50",
          }}
        >
          <a className="navbar-brand" href="/">
            <div className="sss">
              {/* <div className="d-flex flex-column align-items-center"> */}
              <img
                src={logo}
                alt="Your Logo"
                height="40"
                className="d-inline-block align-top yup"
              />
              {/* </div> */}
            </div>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-center justify-content-lg-between"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0 text-center mx-auto">
              <li className="nav-item nav-link">
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    fontWeight: "500",
                    color: "#222222",
                    fontSize: "17px",
                  }}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item nav-link">
                <Link
                  to="/about"
                  style={{
                    textDecoration: "none",
                    fontWeight: "500",
                    color: "#222222",
                    fontSize: "17px",
                  }}
                >
                  About us
                </Link>
              </li>

              <li className="nav-item nav-link">
                <Link
                  to="/nurse"
                  style={{
                    fontSize: "17px",
                    textDecoration: "none",
                    fontWeight: "500",
                    color: "#222222",
                    fontSize: "17px",
                  }}
                >
                  Nurses
                </Link>
              </li>

              <li className="nav-item nav-link">
                <Link
                  to="/practice"
                  style={{
                    textDecoration: "none",
                    fontWeight: "500",
                    color: "#222222",
                    fontSize: "17px",
                  }}
                >
                  Practices
                </Link>
              </li>
              <li className="nav-item nav-link">
                <Link
                  to="/faq/nurse"
                  style={{
                    textDecoration: "none",
                    fontWeight: "500",
                    color: "#222222",
                    fontSize: "17px",
                  }}
                >
                  FAQ's
                </Link>
              </li>
            </ul>
            <div className="eee">
              <form className="d-flex justify-content-center" role="search">
                {
                  <div className="main-parent-set">
                    <Link to="/contact" style={{ textDecoration: "none" }}>
                      <div className="button-settled-main">
                        <button>Contact us</button>
                      </div>
                    </Link>

                    <div className="button-settled-main main-ppp">
                      <a href="https://web.nomadnurse.co.uk/#login">
                        <button style={{ pointerEvents: "none" }}>Login</button>
                      </a>
                    </div>
                  </div>
                }
              </form>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

import React, { useState, useEffect, useRef } from "react";
import "./Nurse.css";
import Tooth from "../../Assetss/tooth.png";
import Rect from "../../Assetss/Rectangle.jpg";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";
import { animateScroll as scroll } from "react-scroll";
import emailjs from "@emailjs/browser";

import background from "../../Assetss/Capawad 2.png";
import nurse from "../../Assetss/nurseIcon.png";
import practice from "../../Assetss/practiceIcon.png";
import logo1 from "../../Assetss/apple (1).png";
import logo2 from "../../Assetss/Group.png";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
const Data = [
  { animation: "fadeInFromLeft", text: "Industry Leading Pay" },
  { animation: "fadeOutToRight", text: "Industry Leading Pay" },
  { animation: "fadeInFromRight", text: "Weekly Payments" },
  { animation: "fadeOutToBottom", text: "Weekly Payments" },
  { animation: "fadeInFromBottom", text: "Flexibility" },
  { animation: "fadeOutToTop", text: "Flexibility" },
  { animation: "fadeInFromTop", text: "Bonuses" },
  { animation: "fadeOutToLeft", text: "Bonuses" },
  { animation: "fadeInFromLeft", text: "Cosmopolitan Scrubs" },
  { animation: "fadeOutToBottom", text: "Cosmopolitan Scrubs" },
  { animation: "fadeInFromBottom", text: "Discounted Dental Treatments" },
  { animation: "fadeOutToRight", text: "Discounted Dental Treatments" },
  { animation: "fadeInFromRight", text: "Interactive App" },
  { animation: "fadeOutToLeft", text: "Interactive App" },
];

const Nurse = () => {
  const navigate = useNavigate();
  const form = useRef();
  useEffect(() => {
    AOS.init({ duration: 800, easing: "ease-out" });
  }, []);
  const [counter, setCounter] = React.useState(0);
  const [data, setData] = React.useState(Data[0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCounter) => (prevCounter + 1) % Data.length);
    }, 1500);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setData(Data[counter]);
  }, [counter]);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  const [showForm, setShowForm] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    role: "",
    email: "",
    consentCheckbox: false,
  });
  const [errors, setErrors] = useState({});
  const [submitMessage, setSubmitMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = () => {
    setShowForm(false);
    setFormData({
      name: "",
      role: "",
      email: "",
      consentCheckbox: false,
    });
    setErrors({});
    setSubmitMessage("");
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const handleNurseClick = (e) => {
    e.preventDefault();
    setShowForm(false);
    setShowConfirmationModal(true);
  };

  const handleConfirmationClose = () => setShowConfirmationModal(false);

  const handleProceed = () => {
    setShowConfirmationModal(false);
    window.location.href =
      "https://web.nomadnurse.co.uk/#nurse-email-verification";
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await fetch(
          "https://api.drluisa.co.uk/nomad/send-email",
          {
            method: "POST",
            body: JSON.stringify({
              subject: "New Registration Request",
              text: `Name: ${formData.name}\nRole: ${formData.role}\nUser Email: ${formData.email}.`,
            }),
            headers: { "Content-Type": "application/json" },
          }
        );
        const data = await response.json();
        setSubmitMessage(data.message);

        setShowForm(false);

        setFormData({
          name: "",
          role: "",
          email: "",
          consentCheckbox: false,
        });

        setShowSuccessModal(true);
      } catch (error) {
        console.error(error);
        setSubmitMessage("Failed to send email");
      }
    } else {
      setSubmitMessage("Form validation failed");
    }
  };
  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    if (!formData.role) {
      errors.role = "Please select a role";
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = "Invalid email address";
    }

    if (!formData.consentCheckbox) {
      errors.consentCheckbox = "You must consent to the privacy policy";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleCloseSuccessModal = () => setShowSuccessModal(false);

  return (
    <div className="nurse-container">
      <div className="background">
        <img src={background} alt="" />
      </div>
      <div className="nurse-full-contained">
        <div
          className="nurse-left"
          data-aos="fade-right"
          data-aos-offset="200"
          data-aos-duration="600"
        >
          {/* <div className="nurse-logo ">
            <img src={Tooth} alt="" />
          </div> */}
          <div
            className="nurse-heading"
            // data-aos="zoom-in"
            // data-aos-duration="800"
          >
            <h2>
              Why <span style={{ color: "#497367" }}>Nurses</span> should join
              us!
            </h2>
          </div>
          <div className="nurse-para">
            <p>
              At Nomad Nurse, we are redefining the experience as we understand
              the challenges faced by both dental practices and nurses in the
              industry. By choosing to work with us, dental nurses gain access
              to a seamless and user-friendly platform that connects them with
              unlimited opportunities.
            </p>
          </div>
          <div className="nurse-para">
            <p>
              We prioritise transparency, reliability, and efficiency in the
              recruitment process, ensuring that every match is not just a
              placement but a harmonious collaboration.
            </p>
          </div>
          <div className="nurse-para">
            <p>
              Join us in shaping the future of dental care staffing, where your
              skills are valued, and your career is empowered.
            </p>
          </div>

          <div className="nurse-button">
            <button onClick={handleOpenForm}>Join us</button>
          </div>
          <Modal
            show={showForm}
            onHide={handleCloseForm}
            centered // Add this prop to center the modal vertically
          >
            <Modal.Header closeButton>
              <div className="please-selcettt">Please select your role</div>{" "}
            </Modal.Header>
            <Modal.Body>
              <div className="nurse-practice-containerrrr">
                <div className="nurse-image-flexxxxxx">
                  {/* <a
                    href="https://web.nomadnurse.co.uk/#nurse-email-verification"
                    style={{ textDecoration: "none", color: "white" }}
                  > */}
                  <div className="image-text-open" onClick={handleNurseClick}>
                    <img src={nurse} alt="" />
                    <div className="nurse-container-opennnn">
                      <p>Nurse</p>
                    </div>
                  </div>
                  {/* </a> */}
                  <a
                    href="https://web.nomadnurse.co.uk/#practice-email-verification"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <div className="image-text-open">
                      <img src={practice} alt="" />
                      <div className="nurse-container-opennnn">
                        <p>Practice</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="button-container-fluxxxxxxxxxxxxxxx">
                  <a href="https://apps.apple.com/app/nomad-nurse-app/id6503719145">
                    <button>
                      <div className="img-hhhhel">
                        <div className="img-hhhhel-ggg">
                          <img src={logo1} alt="" />
                        </div>
                        Download App
                      </div>
                    </button>
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.nomad_nurse.app&pli=1">
                    <button>
                      <div className="img-hhhhel">
                        <div className="img-hhhhel-ggg">
                          <img src={logo2} alt="" />
                        </div>
                        Download App
                      </div>
                    </button>
                  </a>
                </div>
                <div className="forget-para-tell">
                  <p>
                    Don’t forget to download the app on your mobile phone too
                  </p>
                </div>
              </div>
            </Modal.Body>
            {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseForm}>
              Close
            </Button>
          </Modal.Footer> */}
          </Modal>
          <Modal
            show={showSuccessModal}
            onHide={handleCloseSuccessModal}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Form Successfully Submitted</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Your form has been successfully submitted.</p>
            </Modal.Body>
          </Modal>
          <Modal
            show={showConfirmationModal}
            onHide={handleConfirmationClose}
            centered
          >
            <Modal.Body>
              <div className="nurse-proceed-container-shop">
                <div className="before-you-proceed">Before you start:</div>
                <div className="you-before-proceed">
                  You will need to upload the following items as part of your{" "}
                  application :
                </div>
                <ul id="testul">
                  <li>GDC Certificate</li>
                  <li>Indemnity Certificate</li>
                  <li>Enhanced DBS</li>
                  <li>Hepatitis B</li>
                </ul>
                <div className="you-before-proceed-deep">
                  You will also need to enter your bank details for payments
                </div>
                <div className="proceed-cancel-buttons-orifin">
                  <div className="proceed-cancel-buttons">
                    <div
                      onClick={handleProceed}
                      className="proceed-button-orifin"
                    >
                      Proceed
                    </div>
                    <div
                      className="proceed-button-orifin"
                      onClick={handleCancel}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <div
          className="nurse-right"
          data-aos="fade-left"
          data-aos-offset="200"
          data-aos-duration="600"
        >
          <div className="hmmmmm"></div>
          <div className="nurse-animation ">
            <p className={data.animation}>{data.text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nurse;

import React, { useState, useEffect } from "react";
import "./Discount.css";
// import Video1 from "../Discount/video.mp4";
import Video1 from "../Discount/video-compress-again.mp4";
import Tooth from "../../Assetss/tooth.png";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";
import { animateScroll as scroll } from "react-scroll";
import yourGif from "../Discount/ezgif copy.gif";
import axios from "axios";
import imgg from "../../Components/Discount/video-0.jpg";
import discount from "../../Assetss/discount.jpeg";
import nurse from "../../Assetss/nurseIcon.png";
import practice from "../../Assetss/practiceIcon.png";
import logo1 from "../../Assetss/apple (1).png";
import logo2 from "../../Assetss/Group.png";

const Data = [
  { animation: "fadeInFromLeft", text: "Efficiency" },
  { animation: "fadeOutToRight", text: "Efficiency" },
  { animation: "fadeInFromRight", text: "Convenience" },
  { animation: "fadeOutToBottom", text: "Convenience" },
  { animation: "fadeInFromBottom", text: "Reliability" },
  { animation: "fadeOutToTop", text: "Reliability" },
  { animation: "fadeInFromTop", text: "Flexibility" },
  { animation: "fadeOutToLeft", text: "Flexibility" },
  { animation: "fadeInFromLeft", text: "Transparency" },
  { animation: "fadeOutToBottom", text: "Transparency" },
  { animation: "fadeInFromBottom", text: "Instantaneous Bookings" },
  { animation: "fadeOutToRight", text: "Instantaneous Bookings" },
];

const Discount = () => {
  const navigate = useNavigate();
  const [counter, setCounter] = React.useState(0);
  const [data, setData] = React.useState(Data[0]);
  const [showImage, setShowImage] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCounter) => (prevCounter + 1) % Data.length);
    }, 1500);

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []); // Empty dependency array to run effect only once on mount

  useEffect(() => {
    setData(Data[counter]);
  }, [counter]);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const [showForm, setShowForm] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    role: "",
    email: "",
    consentCheckbox: false, // Initialize to false
  });
  const [errors, setErrors] = useState({});
  const [submitMessage, setSubmitMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = () => {
    setShowForm(false);
    setFormData({
      name: "",
      role: "",
      email: "",
      consentCheckbox: false,
    });
    setErrors({});
    setSubmitMessage("");
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    // Clear the error when the user starts typing in the field
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const handleNurseClick = (e) => {
    e.preventDefault();
    setShowForm(false);
    setShowConfirmationModal(true);
  };

  const handleConfirmationClose = () => setShowConfirmationModal(false);

  const handleProceed = () => {
    setShowConfirmationModal(false);
    window.location.href =
      "https://web.nomadnurse.co.uk/#nurse-email-verification";
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await fetch(
          "https://api.drluisa.co.uk/nomad/send-email",
          {
            method: "POST",
            body: JSON.stringify({
              subject: "New Registration Request",
              text: `Name: ${formData.name}\nRole: ${formData.role}\nUser Email: ${formData.email}.`,
            }),
            headers: { "Content-Type": "application/json" },
          }
        );
        const data = await response.json();
        setSubmitMessage(data.message);

        // Close registration form modal
        setShowForm(false);

        // Reset form input fields
        setFormData({
          name: "",
          role: "",
          email: "",
          consentCheckbox: false,
        });

        // Show success modal
        setShowSuccessModal(true);
      } catch (error) {
        console.error(error);
        setSubmitMessage("Failed to send email");
      }
    } else {
      setSubmitMessage("Form validation failed");
    }
  };

  const validateForm = () => {
    const errors = {};

    // Example validation rules (customize based on your requirements)
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    if (!formData.role) {
      errors.role = "Please select a role";
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = "Invalid email address";
    }

    if (!formData.consentCheckbox) {
      errors.consentCheckbox = "You must consent to the privacy policy";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleCloseSuccessModal = () => setShowSuccessModal(false);

  return (
    <div className="discount-container">
      {/* <img
        src={yourGif}
        className="back-video"
        style={{
          width: "100%",
        }}
      /> */}
      <video
        src={Video1}
        autoPlay
        loop
        muted
        poster={discount}
        preload="auto"
        className="back-video"
        // onError={handleVideoError}
        // onLoadedData={handleVideoLoaded}
      />
      {/* {showImage && (
        <img
          src={discount}
          className="back-video vide-contained"
          style={{
            width: "100%",
            opacity: "50%",
          }}
        />
      )}
      {!showImage && (
        <video
          src={Video1}
          autoPlay
          loop
          muted
          className="back-video vide-contained"
          style={{ display: showImage ? "none" : "block" }}
        />
      )} */}
      {/* <div
        className="video-gap2"
        style={
          videoLoaded || videoError ? { display: "none" } : { display: "block" }
        }
      >
        <img src={imgg} alt="" />
      </div> */}

      <div className="video-gap1">
        <img src={yourGif} alt="GIF" />
      </div>
      <div
        className="flex-width"
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <div className="discount-container-full">
          <div className="left-discount">
            <p className={data.animation}>{data.text}</p>
          </div>
          <div
            className="nurse-left"
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-duration="600"
          >
            {/* <div className="nurse-logo">
              <img src={Tooth} alt="" />
            </div> */}
            <div className="nurse-heading nurse-best">
              <h2>
                Why <span style={{ color: "#497367" }}>Practices</span> should
                join us!
              </h2>
            </div>
            <div className="nurse-para2">
              <p>
                We invite dental practices to elevate their staffing experience
                by joining our dynamic platform. We understand the pivotal role
                that a skilled dental nurse plays in ensuring the smooth
                functioning of a practice.
              </p>
              <p>
                Our user-friendly platform streamlines the recruitment process,
                allowing you to find the perfect match quickly and efficiently.
              </p>
              <p>
                Join us in transforming the way you staff your practice and
                experience the convenience of finding the right dental nurse
                with ease.
              </p>
            </div>

            <div className="nurse-button">
              <button onClick={handleOpenForm}>Join us</button>
            </div>
            {/* <div className="video-gap1">
              <img src={yourGif} alt="GIF" />
            </div> */}
            <Modal show={showForm} onHide={handleCloseForm} centered>
              <Modal.Header closeButton>
                <div className="please-selcettt">Please select your role</div>{" "}
              </Modal.Header>
              <Modal.Body>
                <div className="nurse-practice-containerrrr">
                  <div className="nurse-image-flexxxxxx">
                    {/* <a
                      href="https://web.nomadnurse.co.uk/#create-nurse-account"
                      style={{ textDecoration: "none", color: "white" }}
                    > */}
                    <div className="image-text-open" onClick={handleNurseClick}>
                      <img src={nurse} alt="" />
                      <div className="nurse-container-opennnn">
                        <p>Nurse</p>
                      </div>
                    </div>
                    {/* </a> */}
                    <a
                      href="https://web.nomadnurse.co.uk/#practice-email-verification"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <div className="image-text-open">
                        <img src={practice} alt="" />
                        <div className="nurse-container-opennnn">
                          <p>Practice</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="button-container-fluxxxxxxxxxxxxxxx">
                    <a href="https://apps.apple.com/app/nomad-nurse-app/id6503719145">
                      <button>
                        <div className="img-hhhhel">
                          <div className="img-hhhhel-ggg">
                            <img src={logo1} alt="" />
                          </div>
                          Download App
                        </div>
                      </button>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.nomad_nurse.app&pli=1">
                      <button>
                        <div className="img-hhhhel">
                          <div className="img-hhhhel-ggg">
                            <img src={logo2} alt="" />
                          </div>
                          Download App
                        </div>
                      </button>
                    </a>
                  </div>
                  <div className="forget-para-tell">
                    <p>
                      Don’t forget to download the app on your mobile phone too
                    </p>
                  </div>
                </div>
              </Modal.Body>
              {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseForm}>
              Close
            </Button>
          </Modal.Footer> */}
            </Modal>
            <Modal
              show={showSuccessModal}
              onHide={handleCloseSuccessModal}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Form Successfully Submitted</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Your form has been successfully submitted.</p>
              </Modal.Body>
            </Modal>
            <Modal
              show={showConfirmationModal}
              onHide={handleConfirmationClose}
              centered
            >
              <Modal.Body>
                <div className="nurse-proceed-container-shop">
                  <div className="before-you-proceed">Before you start:</div>
                  <div className="you-before-proceed">
                    You will need to upload the following items as part of your{" "}
                    application :
                  </div>
                  <ul id="testul">
                    <li>GDC Certificate</li>
                    <li>Indemnity Certificate</li>
                    <li>Enhanced DBS</li>
                    <li>Hepatitis B</li>
                  </ul>
                  <div className="you-before-proceed-deep">
                    You will also need to enter your bank details for payments
                  </div>
                  <div className="proceed-cancel-buttons-orifin">
                    <div className="proceed-cancel-buttons">
                      <div
                        onClick={handleProceed}
                        className="proceed-button-orifin"
                      >
                        Proceed
                      </div>
                      <div
                        className="proceed-button-orifin"
                        onClick={handleCancel}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discount;
